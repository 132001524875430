<div class="col-md-6 offset-md-3 mt-5 text-center">
    <div class="card">
        <h4 class="card-header">Giriş</h4>
        <div class="card-body">
            <button class="btn btn-facebook" (click)="login()">
                <i class="fa fa-facebook mr-1"></i>
                Facebook Kullanıcısı ile Giriş yap
            </button>
        </div>
    </div>
</div>