<h2>Edit Account</h2>
<p>Updating the information here will only change it inside this application, it won't (and can't) change anything in the associated Facebook account.</p>
<form *ngIf="account" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="form-group">
        <label>Facebook Id</label>
        <div>{{account.facebookId}}</div>
    </div>
    <div class="form-group">
        <label>Name</label>
        <input type="text" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
            <div *ngIf="f.name.errors.required">Name is required</div>
        </div>
    </div>
    <div class="form-group">
        <label>Extra Info</label>
        <input type="text" formControlName="extraInfo" class="form-control" />
    </div>
    <div class="form-group">
        <button type="submit" [disabled]="loading" class="btn btn-primary">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Save
        </button>
        <a routerLink="../../" class="btn btn-link">Cancel</a>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
    </div>
</form>
<div *ngIf="!account" class="text-center p-3">
    <span class="spinner-border spinner-border-lg align-center"></span>
</div>
