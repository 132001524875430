<!-- nav -->
<nav class="navbar navbar-expand navbar-dark bg-dark" *ngIf="account">
    <div class="navbar-nav">
        <a class="nav-item nav-link" routerLink="/" routerLinkActive="active">Home</a>
        <a class="nav-item nav-link" (click)="logout()">Logout</a>
    </div>
</nav>

<!-- main app container -->
<div class="container pt-4">
    <router-outlet></router-outlet>
</div>

<!-- credits -->
