<h2>You're logged in with Facebook!!</h2>
<p>All accounts from secure api end point:</p>
<table class="table table-striped">
    <thead>
        <tr>
            <th>Id</th>
            <th>Facebook Id</th>
            <th>Name</th>
            <th>Extra Info</th>
            <th></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let account of accounts">
            <td>{{account.id}}</td>
            <td>{{account.facebookId}}</td>
            <td>{{account.name}}</td>
            <td>{{account.extraInfo}}</td>
            <td class="text-right" style="white-space: nowrap">
                <a routerLink="edit/{{account.id}}" class="btn btn-sm btn-primary mr-1">Edit</a>
                <button (click)="deleteAccount(account.id)" class="btn btn-sm btn-danger btn-delete-account" [disabled]="account.isDeleting">
                    <span *ngIf="account.isDeleting" class="spinner-border spinner-border-sm"></span>
                    <span *ngIf="!account.isDeleting">Delete</span>
                </button>
            </td>
        </tr>
        <tr *ngIf="!accounts">
            <td colspan="5" class="text-center">
                <span class="spinner-border spinner-border-lg align-center"></span>
            </td>
        </tr>
    </tbody>
</table>